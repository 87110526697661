.site-alert-container {
	background:$brand-primary;
	padding:15px 0;
}
.site-alert {
    @include container-breakout;
    padding: 0 $grid-gutter-width/2;
	display:flex;
	align-items: center;
	flex-wrap: wrap;

	+ .site-alert {
		margin:20px 0 0 0;

		&.site-country-culture-alert {
			margin: 0 auto;
		}
	}

	.lang-label {
		color: $colorname-white;
		font-weight: 700;
		margin-left: 15px;
		margin-bottom: 0;
	}
}
.site-alert__image {
	max-width:84px;
	padding:0 20px 0 0;
}

.language-selector {
	margin: 1rem 0 0;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
}
.site-alert__description {
	flex:1;
	color:#fff;
	margin:0;
	font-size: 12px;

	a {
		text-decoration: underline;
		color:#fff;
		font-weight:bold;

		&:hover,
		&:focus {
			text-decoration: none;
		}
	}
}

.site-alert__close {
	margin-left: 20px;
}
.site-alert__close,
.site-alert__accept {
	background:$brand-accent;
	border:0;
	color:#ffffff;
	padding:8px 20px;
	font-size:15px;
	text-decoration: none;


	&:hover,
	&:focus {
		background:$colorname-black;
		color: $colorname-white;
	}
}

.site-alert__culture-dropdown {
	height: 38px;
    margin-left: 1rem;
}

.site-culture-alert {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 9999;
}

@media screen and (min-width: $screen-xs) {
	.site-alert__culture-dropdown {
		margin-top: 0;
	}

	.site-alert__description {
		font-size: 16px;
	}
}

@media screen and (min-width: $screen-lg){
	.language-selector {
		width: 45%;
		margin: 0 0 0 1.5rem;
	}
}
