%header-link {
  a {
    text-decoration: none;

    &:hover,
    &:focus,
    &:active {
      text-decoration: underline;
    }
  }
}

%h1 {
  @extend %header-link;
  color: $text-color;
  font-family: $font-family-zilla;
  font-size: $font-size-h1;
  line-height: 42px;
  font-weight: $font-weight-normal;

  @media screen and (min-width: $screen-sm-min){
    font-size: $font-size-h1-desktop;
    line-height: 64px;
  }

  &.light {
    font-weight: $font-weight-normal;
  }
}

@mixin h1-large {
  @extend %h1;
  font-size: 50px;
  line-height: 52px;
  font-weight: $font-weight-bold;

  @media screen and (min-width: $screen-sm-min){
    font-size: 76px;
    line-height: 76px;
  }
}

%h1-large {
  @include h1-large;
}

@mixin h1-large-2 {
  @extend %h1;
  font-size: 50px;
  line-height: 52px;
  font-weight: $font-weight-bold;

  @media screen and (min-width: $screen-sm-min){
    font-size: 60px;
    line-height: 70px;
  }
}

%h1-large-2 {
  @include h1-large-2;
}

h1,
.h1 {
  @extend %h1;

  &.large {
    @extend %h1-large;
  }
}

%h2{
  @extend %header-link;
  font-weight: $font-weight-bold;
  font-family: $font-family-zilla;
  font-size: $font-size-h2;
  line-height: 38px;
}

h2,
.h2 {
  @extend %h2;
}

%h3 {
  @extend %header-link;
  color: $brand-accent;
  font-family: $font-family-zilla;
  font-weight: $font-weight-bold;
  font-size: $font-size-h3;
  line-height: 30px;
}

h3,
.h3 {
  @extend %h3;
}

%h4 {
  @extend %header-link;
  font-family: $font-family-open;
  font-size: $font-size-h4;
  font-weight: $font-weight-bold;
  line-height: 1.4;
  color: $brand-accent;
}

h4,
.h4 {
  @extend %h4;
}

%h5 {
  @extend %header-link;
  font-size: $font-size-h5;
  line-height: $headings-line-height;
  text-transform: uppercase;
  line-height: 18px;
}

h5,
.h5 {
  @extend %h5;
}

.heading-with-border {
  border-bottom:3px solid $brand-primary;
  margin:0 0 30px;
  font-size:$font-size-h2;
}

%p {
  font-size: $font-size-base;
}

p,
.p {
  @extend %p;
}

%light-link {
  cursor: pointer;
  color: $color-text-light;

  &:hover,
  &:focus,
  &:active{
    color: $text-color-inverted;
  }
}

%accent-link,
.accent-link {
  @include remove-link-underline;
  border-bottom: 2px solid $brand-primary;
  color: $text-color;
  line-height: 19px;
  font-weight: $font-weight-bold;

  &:hover,
  &:focus,
  &:active {
    color: $brand-primary;
  }
}

.more-link {
  @extend %accent-link;
  line-height: 24px;
}


a {
  color: $brand-primary;
  text-decoration: underline;

  &:hover,
  &:focus,
  &:active {
    color: $brand-primary-dark;
    text-decoration: none;
  }
  &:focus {
    outline-offset: 0;
  }

  /*
  &[rel='external'],
  &[target='_blank'] {
    position: relative;
    margin-right: 3em;

    &:after {
      @include fa-icon-solid;
      content: '\f360';
      position: absolute;
      left: 100%;
      bottom: 0;
      margin-left: 1ex;
      color: $text-color-deemphasized;
    }
  }
  */
}

%eyebrow-text {
  text-transform: uppercase;
  font-weight: $font-weight-normal;
  font-size: $font-size-small;
}

.eyebrow-text {
  @extend %eyebrow-text;
}

%text-deemphasized {
  @extend %eyebrow-text;
  color: $text-color-deemphasized;
  margin: 0 0 16px;
  font-size: $font-size-small;
}

blockquote {
  padding: 0 $grid-gutter-width;
  border: 0;
  color: $text-color-deemphasized;
  margin: 0 0 $vertical-rhythm-base;
  position: relative;

  &:before {
    content: '\201C';
    left: 0;
    top: 0;
  }

  &:after {
    content: '\201D';
    right: 0;
    bottom: 0;
  }
}

cite {
  display: inline-block;
  width: 100%;
  padding: 0 $grid-gutter-width;
  margin: 0 0 $vertical-rhythm-base;

  &:before {
    content: '\2014';
    margin-right: 1ex;
  }
}

ol,
ul {
  >li {
    margin-bottom: 0.5em;
  }
}

li.list-red-square {
  list-style: none;
  position: relative;

  &:before {
    content: '';
    width: 6px;
    background: $brand-primary;
    position: absolute;
    height: 6px;
    left: -18px;
    top: 8px;
  }
}

%intro-copy {
  @extend %h4;
  font-weight: $font-weight-normal;
  line-height: 1.4;
  color: $text-color-deemphasized;

  &:first-child{
    &:not(:only-child){
      margin-bottom: 48px;
    }
  }
}

.intro-copy {
  @extend %intro-copy;

  p {
    font-size: 1em;
  }

  &.intro-copy--products {
    margin-bottom: 35px;
  }
}

@mixin setFont($font)
{
  &, a, h1, h2, h3,h4,h5,h6, p {
    font-family: $font !important;
  }
}

.sarabun-font {
  @include setFont($font-family-sarabun);
}