.results-stats {
  @extend %h5;
  font-size: $font-size-h4;
  color: $text-color-deemphasized;
  text-transform: none;
  line-height: 28px;
  margin: 24px 0;
}

.search-result {
  @include make-md-column(9, 0);
  margin-bottom: 20px;

  .teaser-title {
    color: $brand-primary;
  }

  .teaser-content-footnote {
    word-wrap: break-word;
  }

  .teaser-inner {
    display:flex;
  }
  .teaser-image {
    max-width:200px;
    padding:0 20px 0 0;
  }
}
