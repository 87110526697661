.signode-section {
  // @include make-row;
  margin-bottom: 36px;
  position: relative;
  width: 100vw;
  background-size: cover;
  background-position: center;

  .container {
    @include container-fixed;
    position: relative;
  }

  .container-fluid {
    @include make-row;
    position: relative;
  }

  &.fold-height {
    min-height: 100vh;

    @media screen and (min-width: $screen-md-min) {
      min-height:80vh;
    }

    .container,
    .container-fluid {
      min-height: 80vh;
    }

    .background {
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      height: 100%;
      width: 100vw;
      z-index: $z-background;
    }

    .video-background {
      display: none;
      z-index: 1;
      @media screen and (min-width: $screen-sm-min){
        display: block;
      }
    }
  }

  .content {
    @include make-xs-column(12);
  }

  .section-title {
    @extend %h3;
    display: block;
    margin: 0 0 1em;
    text-align: center;
  }

  .section-image {
    @include make-md-column(6);
  }
}
