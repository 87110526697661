%button {
  @extend .btn;
  @include remove-link-underline;
  font-family: $font-family-open;
  font-size: 18px;
  line-height: 21px;
  font-weight: $font-weight-bold;
  padding: 13px 24px 14px;
  margin-right: 1em;
  border-width: 0;
  white-space: normal;
}

%btn-dark {
  background: $gray-darker;
  color: $text-color-inverted;

  &:hover,
  &:focus,
  &:active {
    background: $colorname-black;
    color: $text-color-inverted;
  }
}

.btn-dark {
  @extend %btn-dark;
}


@mixin outline-button($border-color, $text-color, $hover-text-color) {
  @extend %button;
  padding-top: 11px;
  padding-bottom: 12px;
  border-width: 2px;
  background: transparent;
  border-color: $border-color;
  color: $text-color;

  &:focus,
  &:hover,
  &:active {
    background: $border-color;
    color: $hover-text-color;
    border-color: $border-color;
  }
}

.btn {
  @extend %button;
}

%btn-outline-primary {
  @include outline-button($brand-primary, $text-color, $text-color-inverted);
}

.btn-outline-primary {
  @extend %btn-outline-primary;
}

%btn-outline-white {
  @include outline-button( $color-body, $color-body,  $brand-primary);
}

.btn-outline-white {
  @extend %btn-outline-white;
}

%btn-outline-black {
  @include outline-button( $text-color, $text-color,  $color-body);
}

