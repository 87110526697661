form {
  position: relative;
}

label {
  font-family: $font-family-open;
  font-size:  18px;
  font-weight: $font-weight-bold;
}

%form-element-outline {
  border: 1px solid $color-form-border;
  border-radius: $border-radius-base;
  position: relative;
}

%select-wrapper {
  @extend %form-element-outline;
  margin: 0.5em 0;
  margin-right: 24px;
  display: inline-block;

  select {
    appearance: none;
    -ms-appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    border: none;
    background: transparent;
    width: 100%;
    padding: 11px 48px 11px 16px;

    &::-ms-expand {
      display: none;
    }
  }

  &:after {
    content: '\f078';
    color: $brand-primary;
    font-family: $font-family-font-awesome;
    font-size: 0.8em;
    font-weight: $font-weight-fa-solid;
    position: absolute;
    right: 1em;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }
}

%label-small-top {
  font-family: $font-family-roboto;
  font-size: 12px;
  font-weight: $font-weight-bold;
  margin-bottom: 0;
}

%label-inline {
  font-family: $font-family-open;
  font-size: 16px;
  font-weight: $font-weight-normal;
  display: inline;
  vertical-align: middle;
  padding: 0;
  float: none;
}

%faux-input {
  position: relative;
  margin: 0 1ex 0 0;
  height: 16px;
  width: 16px;
  visibility: hidden;

  &:before {
    content: '';
    height: 16px;
    width: 16px;
    visibility: visible;
    border: 1px solid #000;
    display: inline-block;
    vertical-align: top;
  }

  &:checked {
    &:after {
      visibility: visible;
      content: '';
      height: 8px;
      width: 8px;
      background: #000;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 10;
    }
  }
}

.select-wrapper {
  @extend %select-wrapper;

  &.select-wrapper--alt {
    background:#b33d26;
    color:#fff;

    select option {
      color:#000;
    }

    &:after {
      color:#fff;
    }
  }
}

.field-group {
  display: inline;
  text-align: left;

  .field-label {
    @extend %label-small-top;
  }
}

.filters {
  margin:0 0 15px;
  
  .field-label {
    font-size:16px;
    margin-right:5px;
  }
}

.input-group {
  margin-bottom: $vertical-rhythm-base;

  .field-label {
    @extend %label-small-top;
    float: left;
    clear: both;
  }

  &.radio-group {
    // display: flex;
    // justify-content: center;
    // flex-direction: row;
    // flex: 1 0 100%;
    .field-label {
      @extend %label-inline;
      flex: 1 0 auto;
    }

    input[type='radio'] {
      height: 1em;
      margin-top: -0.5ex;
      margin-right: 1ex;
      display: inline-block;
      vertical-align: middle;
    }
  }

  &.checkbox-group {
    .field-label {
      @extend %label-inline;
    }

    input[type='checkbox'] {
      margin-right: 1ex;
    }
  }

  &.required {
    .field-label {
      position: relative;
      padding-right: 1ex;

      &:after {
        content: '*';
        color: $brand-danger;
        position: absolute;
        top: 0;
        right: 0;
        line-height: 1;
      }
    }
  }

  &.text {
    input {
      @extend %form-element-outline;
      width: 100%;
      padding: 1em 1ex;
    }
  }

  &.textarea {
    textarea {
      @extend %form-element-outline;
      width: 100%;
      padding: 1em 1ex;
      min-height: 10em;
    }
  }
}

.captcha {
  width: 100%;
  margin-bottom: $vertical-rhythm-base;
}

%form-submit {
  @include make-button-primary;
  padding: 12px 21px;
}

.form-submit {
  @extend %form-submit;
}

.inline-form {
  max-width: 535px;
  position: relative;
  width: 100%;
  margin: $vertical-rhythm-base 0;

  .form-group {
    position: relative;
    border: 1px solid $color-form-border;
    border-radius: $border-radius-large;

    @media screen and (min-width: $screen-md-min) {
      margin: 0;
    }
  }

  %search-text {
    font-family: $font-family-open;
    font-size:  18px;
    font-weight: $font-weight-bold;
    padding-left: 21px;
  }

  .search-field {
    @extend %search-text;
    @include field-label-overlay('.search-label');
    background: $colorname-white;
    border: 0;
    border-radius: $border-radius-large;
    line-height: 21px;
    padding: 20px 21px 22px;
    width: 100%;

    @media screen and (min-width: $screen-sm-min) {
      padding-right: 136px;
    }
  }

  .search-label {
    @extend %search-text;
  }

  .search-suggestions {
    position: absolute;
    width:calc(100% + 2px);
    left:-1px;
    z-index:10;
    top:calc(100% + 5px);
    border: 1px solid $color-form-border;
    border-top:0;
    border-radius: 0 0 $border-radius-large $border-radius-large;
    background:#fff;
    padding:5px;
    text-align: left;

    @at-root .homepage-section-full .inline-form .search-suggestions {
      border:0;
      width:100%;
      left:0;
    }
  }
  .search-suggestions__link {
    display:block;
    padding:5px;
  }

  .search-submit {
    @extend %form-submit;
    @include vertical-center;
    right: 8px;
  }
}

/* formbuilder */

.form,
[id^='form-'] form {
	label {
		font-weight:bold;
		margin:0 0 5px;
		display:block;
	}
	input[type="text"],
	input[type="email"],
	input[type="tel"],
	input[type="password"],
	select
	 {
		width:100%;
		margin:0 0 20px;
	}
	textarea {
		width:100%;
		margin:0 0 20px;
	}

	.ktc-radio-list {
		margin:0 0 20px;
		display:block;
	}
	.ktc-radio-list .ktc-radio {
		display:flex;
		margin:0 0 5px;

		input[type="radio"] {
			margin:5px 5px 0 0;
		}

		label {
			margin:0;
		}
	}

	.field-validation-error {
		margin:-15px 0 15px;
		display:block;
		font-style: italic;
		color:$brand-danger;
	}

	.g-recaptcha {
		margin:0 0 20px;

		+ .error {
			margin:-15px 0 15px;
			display:block;
			font-style: italic;
			color:$brand-danger;
		}
	}

	input[type="submit"] {
    @extend %button;
    @extend .btn-primary;
	}
}