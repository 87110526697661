// GREY BLOCK
.cta-block {
  @include signode-texture;
  width: 100%;
  padding: 32px 24px 40px;
  margin: 0 0 48px;

  .btn,
  a {
    &[rel='external'],
    &[target='_blank'] {
      margin-right: 0;
      &:after {
        display: none;
      }
    }
  }

  @media screen and (min-width: $screen-sm-min) {
    padding: 32px 40px 48px;
  }

  &.transparent {
    background: transparent;
  }

  &.slim {
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .cta-headline {
    @extend %h3;
    margin-top: 0;
    color: $colorname-black;
    margin-bottom: 24px;
  }

  .cta-btn {
    @extend .btn-primary;
  }
}

//------------------

.cta-block-featured {
  background: $brand-primary;
  margin: 0;

  @media screen and (min-width: $screen-sm-min) {
    padding-bottom: 24px;
  }
  @media screen and (min-width: $screen-md-min) {
    padding-bottom: 48px;
  }

  .cta-headline {
    color: $color-body;
    line-height: 30px;
  }

  .cta-btn {
    @extend %btn-outline-white;
    margin: 0;
  }

  .col-2-violator-up & {

    @media screen and (min-width: $container-breakout-max) {
      padding-right: 15px;
    }
  }
}
