.applied-filter {
  border-radius: 15px;
  border: 1px solid $colorname-silver;
  height: 30px;
  padding: 3px 12px;
  line-height: 19px;
  font-size: 14px;
  margin: 0.5em 1em 32px;
  margin-left: 0;
  color: $colorname-slate-blue;
  display: inline-block;
}

.clear-filter-btn {
  @include remove-link-underline;
  border: 0;
  background: none;
  margin-left: 1em;
  padding: 0;
}

.filter-toggle {
  @extend %select-wrapper;
  padding-right: 3em;

  &.active,
  &:hover,
  &:focus,
  &:active {
    background: $brand-primary;
    color: $text-color-inverted;

    &:after {
      color: $text-color-inverted;
    }
  }
}

.clear-filters {
  display: block;
  @media screen and (min-width: $screen-sm-min){
    display: inline-block;
  }
}

.filter-submit {
  @extend .btn-primary;
  margin-right: 0;
}

.filter-modal {
  position: relative;
  width: 100vw;
  margin-left: -15px;
  margin-right: -15px;
  @media screen and (min-width: $screen-md-min){
    margin: 0;
    width: 75%;
  }

  .modal {
    max-width: 100%;
    max-height: unset;
    width: 100vw;
    position: absolute;
    top: 100%;
    margin-top: -2em;
    background: $color-body;
    z-index: 5;
    box-sizing: border-box;
    border-radius: $border-radius-base;
    transform: none;
    left: 0;
    padding: $grid-gutter-width $grid-gutter-width/2;
    border: 1px solid $text-color;

    @media screen and (min-width: $screen-sm-min){
      margin-top: 0;
      box-shadow: 0 2px 20px 0 $color-shadow-light;
      padding: 16px 16px 32px;
      width: 100%;
    }

    .modal-content {
      padding: 0;
    }

    .modal-body {
      padding: 0;
    }

    .modal-footer {
      padding: 16px $grid-gutter-width/2 0;
      @media screen and (min-width: $screen-sm-min){
        padding-top: 0;
      }
    }
  }

  .modal-body {
    width: 100%;
    float: left;
    text-align: left;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    align-content: top;

    .filter-section {
      display: block;
      flex: 1 0 100%;

      justify-self: flex-end;

      @media screen and (min-width: $screen-sm-min){
        flex-basis: 25%;
        padding: 0 $grid-gutter-width/2;
      }

      .input-group {
        width: 100%;
      }
    }

    @media screen and (min-width: $screen-sm-min){
      box-shadow: none;
      padding-bottom: 20px;
    }
  }

  .modal-footer {
    width: 100%;
    float: left;
    text-align: left;

    @media screen and (min-width: $screen-sm-min){
      border: 0;
      padding-top: 0;
    }

    .btn {
      margin-right: 1em;
    }
  }

  .filter-submit {
    @extend .btn-outline-primary;
    font-size: 16px;
    padding-top: 11px;
    padding-bottom: 11px;
  }
}

.inline-filters {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-end;

  .field-group {
    flex: 1 0 100%;
    margin: 0 0 8px;

    @media screen and (min-width: $screen-sm-min){
      flex: 1 0 20%;
      margin-right: 1em;
      margin-bottom: 0;
    }
  }

  .select-wrapper {
    width: 100%;
  }

  .filter-submit {
    @extend .btn-primary;
    flex: 0 1 120px;
    margin-bottom: 8px;
  }
}
