%figure {
  position: relative;

  img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}

%figure-16-9 {
  @extend %figure;
  @include aspect-ratio(16, 9);

  img {
    @extend %object-fit-cover;
  }
}

.figure-16-9 {
  @extend %figure-16-9;

  img {
    @extend %object-fit-cover;
  }
}

%figure-square {
  @extend %figure;
  @include aspect-ratio(1, 1);

  img {
    @extend %object-fit-cover;
  }
}

.figure-square {
  @extend %figure-square;
}

%image-float {
  margin: 24px 35px;

  img {
    max-width: 100%;
  }

}

.image-left {
  @extend %image-float;
  float: left;
  margin-left: 0;
  max-width: 400px;
}

.image-right {
  @extend %image-float;
  float: right;
  margin-right: 0;
  max-width: 400px;
}

.image-full {
  @extend %image-float;
  margin-right: 0;
  margin-left: 0;
  max-width: 100%;
}

.image-caption {
  display: inline-block;
  color: $text-color-deemphasized;
  margin: 16px 0;
  font-size: $font-size-small;
}

%object-fit-cover{
  object-fit: cover;
  object-fit-position: center;
}

%object-fit-contain {
  object-fit: contain;
  object-fit-position: center;
}

.object-fit-cover{
  @extend %object-fit-cover;
}
