%teaser-grid-homepage-item {
  @include make-xs-column(12);
  @include make-sm-column(12);
  @include make-xs-column(12);
  display: block;
  flex: 1 0 80%; // less that 100% so it can grow up to 100%
  box-sizing: border-box;
}

%teaser-grid-homepage-base {
  display: flex;
  flex-wrap: wrap;
  align-items: top;
  max-width: 100vw;
  width: 100%;
  margin-left: 0;
  margin-right: 0;

  @media screen and (min-width: $screen-lg-min ){
    flex-wrap: nowrap;
  }

  .teaser-grid-item {
    @extend %teaser-grid-homepage-item;
    // flex: 1 0 80%; // prevent anything from collapse

    .figure-16-9 {
      @include make-row($grid-gutter-width);
      width: calc(100% + #{$grid-gutter-width});

      @media screen and (min-width: $screen-sm-min){
        width: 100%;
        margin-left: 0;
        margin-right: 0;
      }
    }

    @media screen and (min-width: $screen-sm-min) {
      flex: 1 0 40%; // grow to 50%
    }
  }
}

// latest from signode section
.teaser-grid-homepage-featured {
  @extend %teaser-grid-homepage-base;

  @media screen and (min-width: $screen-sm-min) {
    margin-right: -15px; // match larger border
    margin-left: -15px; // match larger border
    width: calc(100% + 30px);
    max-width: 100vw;
  }

  &:after,
  .teaser-grid-item {
    @extend %teaser-grid-homepage-item;

    + .teaser-grid-item {
      margin-top: $grid-gutter-width;
    }

    @media screen and (min-width: $screen-sm-min) {
      padding: 24px 16px 16px;

      &:nth-of-type(odd) { //
        border-right: 1px solid $gray-lighter;
      }

      &.no-border {
        border: 0;
      }

      + .teaser-grid-item {
        margin-top: 0;
      }
    }

    @media screen and (min-width: $screen-lg-min ){
      flex: 1 0 20%; // grow to 25%
      border-right: 1px solid $gray-lighter;

      &:last-of-type {
        border-right: 0;
      }
    }
  }
}

.teaser-grid-homepage {
  @extend %teaser-grid-homepage-base;

  .teaser-grid-item {
    @extend %teaser-grid-homepage-item;
    @media screen and (min-width: $screen-sm-min) {
      padding: 24px 20px 16px;
    }
  }
}

.homepage-section {
  .teaser-grid-2-up {
    @extend %teaser-grid-homepage-base;

    .teaser-grid-item {

      .figure-16-9 {
        @include make-row($grid-gutter-width);
        width: calc(100% + #{$grid-gutter-width});

        @media screen and (min-width: $screen-sm-min){
          width: 100%;
          margin-left: 0;
          margin-right: 0;
        }
      }

      @media screen and (min-width: $screen-sm-min) {
        flex: 1 0 40%; // grow to 50%
      }
    }
  }
}
