/* ================================================================
   Content Image Split
   ================================================================ */

.content-image-split {
    margin:0 0 25px;

    + .content-image-split {
        padding-top:25px;
        border-top:1px solid #aaaaaa;
    }
}
.content-image-split__image {
	height:200px;
	background-size:cover;
	background-position: 50% 50%;
	position:relative;
}
.content-image-split__content {
	padding:25px 0 0 ;
}

@media screen and (min-width: $screen-sm) {
	.content-image-split {
		display:flex;
		margin:0 auto 25px;
	}
	.content-image-split__image {
		width:50%;
        height:auto;
        min-height:300px;
	}
	.content-image-split__content {
		width:50%;
		padding:0;
		padding-right:15px;
	}
	.content-image-split__content__inner {
		max-width:600px;
		padding:15px 0 15px 20px;
	}
	
	.content-image-split--image-right {
		.content-image-split__image {
			order:1;
		}
		.content-image-split__content {
            padding:0 0 0 15px;
            text-align:right;
		}
		.content-image-split__content__inner {
			padding:15px 20px 15px 0;
			float: right;
			width:100%;
		}
	}
}