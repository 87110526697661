.teaser-image {
  margin-bottom: 16px;

  img {
    border: 1px solid $colorname-alto8-gray;
  }
}

.teaser-content {

  @media screen and (min-width: $screen-xs-min){
    padding: 0;
  }
}

.teaser-category {
  @extend %accent-link;
  @extend %eyebrow-text;

  + .teaser-title {
    margin-top: 8px;
  }
}

.teaser-title {
  @extend %h3;
  color:  $colorname-black-thunder;
  margin-top: 0;
  margin-bottom: 6px;

  @media screen and (min-width: $screen-sm-min){
    margin-top: 10px;
  }

  a {
    text-decoration: none;

    &:hover,
    &:focus {
      color:#000;
      text-decoration: none;
    }
    
    &:after {
      display: none;
    }
  }
}

.teaser-supplemental {
  @extend %text-deemphasized;
}

.teaser-content-footnote {
  color: $text-color-deemphasized;
}

// teaser-horizontal
%teaser-horizontal-base {

  @media screen and (min-width: $screen-xs-min) {
    display: flex;

    .teaser-content {
      margin-top: 0;
      margin-left: 24px;
      width:100%;
    }

    .teaser-title {
      margin-top: 0;
    }

    .teaser-image {
      margin-bottom: 0;
    }
  }
}

// default horizontal
.teaser-horizontal {
  @extend %teaser-horizontal-base;

  .teaser-image {

    @media screen and (min-width: $screen-xs-min) {
      width: 100px;
    }

    @media screen and (min-width: $screen-md-min) {
      width: 225px;
    }

    img {
      width: 100%;


      
    }
  }
  .teaser-content {
    @media screen and (min-width: $screen-xs-min) {
      width: calc(100% - 100px);
    }

    @media screen and (min-width: $screen-md-min) {
      width: calc(100% - 225px);
    }
  }
}

// smaller image, goes away on small
.teaser-horizontal-small {
  @extend %teaser-horizontal-base;

  .teaser-image {
    // default to display none
    // displayed as defined in teaser-horizontal-base
    display: none;

    @media screen and (min-width: $screen-xs-min) {
      display: block;

      img {
        width: 100px;
      }
    }
  }
}

.homepage-section-breakout {
  .teaser-content {
    border-bottom-width: 1px;
    border-bottom-color: $gray-lighter;
    border-bottom-style: solid;
    padding-bottom: $grid-gutter-width;

    @media screen and (min-width: $screen-sm-min) {
      border-bottom-width: 0;
      padding: 0;
    }
  }
}
