%homepage-section,
.homepage-section {
  @include container-fluid;

  @media screen and (max-width: $screen-xs-max ) {
    padding: 0;
  }
  margin-bottom: 48px;

  &:last-of-type {
    margin-bottom: 0;
  }

  .section-header {
    @include make-row(0);
  }

  .section-header-content {
    @include make-md-column(8);
    @include make-md-column-offset(2);
    @include make-lg-column(6);
    @include make-lg-column-offset(3);
    text-align: center;
  }

  &.dark {
    background: $section-dark;

    .watermark {
      opacity: 0.2;
      color: $text-color-inverted;
    }
  }

  &.spotlight {
    .teaser-title {
      @extend %h2;
    }

    .teaser-footer {
      margin-top: 12px;
    }
  }
}

.homepage-section-breakout {
  @extend %homepage-section;
  max-width: 1440px;
}

.homepage-section-title {
  @extend %h2;
  font-family: $font-family-zilla;
  font-weight: $font-weight-bold;
  text-align: center;
  margin: 0 0 40px;
}

/* footer offset for homepage */
.signode-solution-system,
.signode-solution-system:last-of-type {
  margin:0 auto -70px;
}
.signode-solution-system + .homepage-section.spotlight {
  margin-top:118px;
}
@media screen and (min-width: $screen-md-min){
  .signode-solution-system,
  .signode-solution-system:last-of-type {
    margin:0 auto -100px;
  }
  .signode-solution-system + .homepage-section.spotlight {
    margin-top:148px;
  }
}