.accordion-button {
    display:block;
    width:100%;
    border:0;
    border-bottom:1px solid #444444;
    padding:10px 0;
    background:none;
    text-align:left;
    font-weight:bold;
    font-size:$font-size-h4;
    color:$brand-primary;

    &:after {
        content:'\f105';
        position: relative;
        font-family: 'Font Awesome 5 Free';
        display:inline-block;
        margin:0 0 0 10px;
    }

    &.active:after {
        transform: rotate(90deg);
    }

    &:hover,
    &:focus {
        color:$brand-accent;
        outline:none;
    }
}
.accordion-content {
    padding:15px 0;

    .accordion-button {
        margin-left:30px;
        width:calc(100% - 30px);
    }
    .accordion-content {
        padding-left:30px;
    }

    &:hover,
    &:focus {
        outline:none;
    }
}