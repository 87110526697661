.card-wrap {
  @include make-row;
  align-items: top;
  display: flex;
  flex-wrap: wrap;
  justify-items: space-between;
  @media screen and (min-width: $screen-md-min ){
    flex-wrap: nowrap;
  }
}

.card {
  display: block;
  flex: 1 0 100%;

  @media screen and (min-width: $screen-sm-min) {
    flex-basis: 48%; // grow to 50%
  }

  @media screen and (min-width: $screen-md-min ){
    flex-basis: 20%; // grow to 25%
  }

  // these just keep the last item from growing huge
  &.placeholder {
    border: 0;
    height: 0;
    margin: 0;
    order: 999999;
    padding: 0;
    @media screen and (min-width: $screen-md-min ){
      display: none;
    }
  }
}
