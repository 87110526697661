// Pagination

.pagination {
  font-size: $font-size-base;
  width: 100%;
  float: left;
  text-align: center;
  white-space: nowrap;


  > .pager-item.pager-item-number {
    display: none;

    @media screen and (min-width: $screen-md-min) {
      display: inline-block;
    }
  }

  > .pager-item-number.pager-item.active {
    display: inline-block;
  }

  > li {
    float: none;
    display: inline-block;

    + li {
      margin-left: -0.5ex; // collapse whitespace between items
    }

    > a,
    > span {
      padding: 11px 17px; // try to get 44 ish touch area on mobile
      font-weight: $font-weight-normal;
      cursor: pointer;

      @media screen and (min-width: $screen-md-min) {
        padding: 6px 12px;
      }
    }
  }
  > .disabled > span,
  > .disabled > a {
    color:#666;

    &:hover,
    &:focus {
      color:#666;
    }
  }

  > .active > a,
  > .active > span {
    font-weight: $font-weight-bold;
  }
}
