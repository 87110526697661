.menu-utility {
  padding: 7px $grid-gutter-width/2 21px;
  @media screen and (min-width: $screen-md-min ) {
    padding: 0;
  }

  // MOBILE ONLY
  .header-bottom & {
    display: block;
    @media screen and (min-width: $screen-md-min ){
      display: none;
    }

    ul ul a {
      color:#ffffff;
    }
  }

  // DESKTOP ONLY
  .header-top & {
    display: none;
    @media screen and (min-width: $screen-md-min ) {
      @include menu-space-around;
      flex: 1 0 80%;

      .nav-item {
        padding: 0;
        margin: 0;
        position: relative;
      }

      ul ul {
        display:none;
        background:$color-nice-orange;
        padding:10px;
        position: absolute;
        z-index: 9999;
        width:200px;

        li {
          margin:5px 0;
          display:block;
        }

        a {
          color:#ffffff;
          font-size:14px;
          text-decoration: none;

          &:hover,
          &:focus {
            text-decoration: underline;
          }
        }
      }
    }
  }

  %utility-nav-link {
    @extend %light-link;
    display: inline-block;
    padding:0;
    border:0;
    background:transparent;
    padding-top: 11px;
    padding-bottom: 11px;
    margin-right: 0;

    &:after {
      display: none;
    }

    &.active {
      border-bottom: 2px solid $brand-primary;
      color: $text-color-inverted;
      margin-bottom: 11px;
      padding-bottom: 0;

    }

    &[data-expander-toggle] {
      position: relative;
      padding-right:20px;

      &:after {
        content:'';
        display:block;
        position: absolute;
        border-top:6px solid #fff;
        border-left:5px solid transparent;
        border-right:5px solid transparent;
        right:0;
        top:17px;
      }
    }

    @media screen and (min-width: $screen-md-min ) {
      display: inline-block;
      border-bottom: 2px solid transparent;
      font-size: 13px;
    }
  }

  .nav-link {
    @extend %utility-nav-link;
  }

  .language-toggle-text,
  .js-language-toggle-text {
    @extend %utility-nav-link;
    background: transparent;
    border: none;
    padding-left: 0;
    padding-right: 0;

    img {
      max-height:22px;
    }
  }
}
