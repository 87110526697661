%sd-breadcrumb {
  $gt: '\003e';
  $lt: '\003c';
  $sd-breadcrumb-separator: $gt !default; //$fa-chevron-right !default;
  $sd-breadcrumb-up-a-level: $lt !default; //$fa-chevron-left !default;
  $nbsp: '\00a0';

  & > li {
    display: inline-block;

    & + li:before {
      content: '#{$sd-breadcrumb-separator}'; // Unicode space added since inline-block means non-collapsing white-space
      padding: 0 3px;
      color: inherit;
    }
  }

  // Show only the last item on mobile and switch to an 'up one level' display
  @media screen and (max-width: $screen-xs-max) {
    & > li {
      display: none;

      // Show only the parent of the current page
      &:nth-last-child(2) {
        display: block;

        &:before {
          content: '#{$sd-breadcrumb-up-a-level}';
        }
      }
    }
  }
}
// Based on bootstrap breadcrumb pattern. Should be applied to an OL element

.breadcrumb-navigation {
  ol {
    @extend %sd-breadcrumb;
    @include list-reset;
    margin-top: $vertical-rhythm-base;
    font-size: $font-size-small;

    & li > a {
      @include remove-link-underline;
      font-weight: $font-weight-normal;
      color: $brand-primary;
      text-transform: uppercase;

      &:hover {
        text-decoration: underline;
      }
    }

    // classname CMSBreadCrumbsCurrentItem needed for Kentico
    & li.CMSBreadCrumbsCurrentItem > span { // sass-lint:disable-line class-name-format
      font-weight: $font-weight-bold;
      display: none;
    }
  }
}


