.global-header {
  @include signode-texture;

  .homepage &,
  .landing & {
    @include background-reset;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 20;
    width: 100%;
  }

  .row {
    @media screen and (min-width: $screen-md-min ) {
      display: flex;
      align-items: center;
    }
  }

  .global-header-container {
    @include container-breakout;
    background-color: $brand-accent;
    color: $colorname-white;
    padding: 0 $grid-gutter-width/2;
    @media screen and (min-width: $screen-md-min){
      padding: 0 40px;
    }
  }

  %mobile-nav-sections {
    border-bottom: solid $section-dark 1px;

    @media screen and (min-width: $screen-md-min ) {
      border-bottom-width: 0;
    }
  }

  // Logo (header left)
  .logo-container {
    @include make-md-column(3);
    @extend %mobile-nav-sections;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;

    @media screen and (min-width: $screen-md-min){
      padding: 0 $grid-gutter-width/2;
    }

    a {
      width: 100%;
    }

    .logo {
      width: 100%;
      max-width: 280px;
      @media screen and (min-width: $screen-md-min){
        max-width: 100%;
      }
    }
  }

  // utility nav only visible below menu primary on above tablet

  .header-top {
    @extend %mobile-nav-sections;

    @media screen and (min-width: $screen-md-min ){
      border-bottom-width: 1px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  ///
  // HEADER SECTIONS
  ///

  .header-right {
    @include make-md-column(9);
    display: none;
    padding: 0;

    @media screen and (min-width: $screen-md-min ){
      padding: 0 $grid-gutter-width/2;
      display: block;
    }
  }

  &.show-menu {
    .header-right {
      display: block;
    }
  }

  // generic navs

  nav {

    ul {
      padding: 0;
      margin: 0;
    }

    li {
      list-style-type: none;
      display: block;
      @media screen and (min-width: $screen-md-min){
        display: inline-block;
      }
    }
  }
}

.nav-item {
  cursor: pointer;
}

.nav-link {
  @include remove-link-underline;
  cursor: pointer;
}

// Utility Nav and Main Nav in their own files

///
///  UTILITY SEARCH
///

.menu-utility-search {
  @include make-xs-column(12);
  @include make-md-column(2);
  width: 100%;
  position: relative;
  padding: 0 $grid-gutter-width/2;
  padding-right: 26px;
  margin-bottom: 22px;
  float:none;


  @media screen and (min-width: $screen-md-min ){
    padding: 0;
    margin-bottom: 0;
    display: inline-block;
    width: 20%;
  }

  .search-suggestions {
    position: absolute;
    width:100%;
    left:0;
    z-index:1001;
    top:100%;
    background:#c4bcb7;
    padding:5px;
    text-align: left;
    border-radius: 0 0 $border-radius-large $border-radius-large;

    .search-suggestions__link {
      display:block;
      padding:5px;
      color:#111111;
      font-size:14px;
    }
  }
}

.menu-utility-search-btn {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 38px;
  padding: 0 12px;

  .menu-utility-search-submit {
    background: transparent;
    border-color: transparent;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 0 12px;
    font-size:0;
  }

  &:before {
    @include fa-icon-solid;
    position: absolute;
    font-size: 13px;
    top: 50%;
    transform: translateY(-50%);
    content: fa-content($fa-var-search);
    color: $colorname-silver-rust;
    pointer-events: none;
  }
}

.menu-utility-search-input {
  @include placeholder-color($colorname-silver-rust);
  height: 45px;
  background: $brand-accent;
  border: none;
  border-bottom: solid $colorname-silver-rust 2px;
  color: $colorname-silver-rust;
  font-weight: $font-weight-bold;
  font-size: $font-size-small;
  padding-left: 12px;
  padding-right: 38px;
  width: 100%;
  margin-top: 1px;

  @media screen and (min-width: $screen-md-min){
    margin-top: 0;
  }
}

///
// MOBILE MENU TOGGLE
///

.mobile-menu-toggle {
  background: transparent;
  color: $text-color-inverted;
  border: none;
  font-size: 10px;
  text-transform: uppercase;
  text-align: center;
  display: inline-block;
  margin: 16px 0 16px 22px;
  width: 32px;
  padding: 0;

  @media screen and (min-width: $screen-md-min) {
    display: none;
  }

  &:before {
    display: inline-block;
    content: '';
    width: 29px;
    height: 32px;
    background-image: url('../images/hamburger-white.svg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  .close {
    display: none;
  }

  .show-menu & {
    text-align: center;

    &:before {
      background-image: url('../images/close-white.svg');
    }

    .open {
      display: none;
    }

    .close {
      display: block;
    }
  }
}
