.location {

  .location-title {
    margin: 0 0 8px;
  }

  .location-type {
    @extend %text-deemphasized;
  }

  .address {
    margin: 0;
  }

  .city-state {
    margin-bottom: 0;
  }

  .phone,
  .fax,
  .email,
  .directions,
  .website {
    margin: 1em 0 0;
    display: inline-block;
    width: 100%;
  }

  .directions {
    width: auto;
  }

  .website,
  .email {
    a {
      color: inherit;

      &:hover,
      &:focus,
      &:active {
        color: $brand-primary;
      }
    }
  }

  .directions {
    @extend %accent-link;
  }
}
