
.video-listing {
    @include list-reset;
    margin:0 -15px;
}
.video-listing__item {
    padding:0 15px 30px;
    margin:0;
}
.video-listing__link {
    display:block;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;

    &:before {
        content:'';
        display: block;
        padding-bottom:56%;
    }

    &:hover,
    &:focus {
        outline:3px solid #000;

        .video-listing__link-inner {
            border-color:$brand-primary;
            &:after {
                border-left-color: $brand-primary;
            }
        }
    }
}
.video-listing__transcript-link {
    display:inline-block;
    margin:7px 0 0 0;
    font-weight:600;
}

.video-listing__link-inner {
    position: absolute;
    top:50%;
    left:50%;
    transform: translateX(-50%) translateY(-50%);
    width:100px;
    height:100px;
    border:10px solid #ffffff;
    opacity: .7;
    border-radius: 50%;

    &:after {
        content:'';
        position: absolute;
        top:50%;
        left:50%;
        transform: translateY(-50%) translateX(-50%);
        margin-left:5px;
        border-top:20px solid transparent;
        border-bottom:20px solid transparent;
        border-left:26px solid #ffffff;
    }
}

@media screen and (min-width: $screen-md-min) {
    .video-listing {
        display:flex;
        flex-wrap: wrap;
    }
    .video-listing__item {
        width:50%;
    }
}

.featured-video-container {
    margin:0 0 30px;
}
.featured-video {
    position: relative;
    display:block;
    height:400px;
    background-position: 50% 50%;
    background-size:cover;

    &:hover,
    &:focus {
        .featured-video__inner {
            border-color:$brand-primary;
            &:after {
                border-left-color: $brand-primary;
            }
        }
    }
}
.featured-video__inner {
    position: absolute;
    top:50%;
    left:50%;
    transform: translateX(-50%) translateY(-50%);
    width:100px;
    height:100px;
    border:10px solid #ffffff;
    opacity: .7;
    border-radius: 50%;

    &:after {
        content:'';
        position: absolute;
        top:50%;
        left:50%;
        transform: translateY(-50%) translateX(-50%);
        margin-left:5px;
        border-top:20px solid transparent;
        border-bottom:20px solid transparent;
        border-left:26px solid #ffffff;
    }
}