.products-split-container {
    margin:0 0 0 0;
}
.products-split-container__block.products-mobile-only {
    margin:15px 0 0;
}
.products-desktop-only {
    display:none;
}
.solutions-hex-container {
    margin:0 auto 50px;
    max-width:112px;
}
.solutions-list {
    @include list-reset;
    position: relative;
    display:flex;
    flex-flow: column;
    overflow:hidden;
    margin:0 0 20px;
}
.solutions-list__item {
    background:#666666;
    padding:2px 8px 2px 8px;
    font-size:13px;
    margin:0 0 2px 0;
    font-weight:bold;
    color:#fff;
    text-transform: uppercase;
    flex:1;
    display:flex;
    align-items:center;

    &:last-child {
        margin:0;
    }
}

.solutions-hex-list {
    @include list-reset;
}
.solutions-hex-list__item {
    position: relative;
    display:block;
    max-width:112px;
    margin:0 auto 35px;

    &.active {
        .solutions-hex-solid {
            fill:$brand-primary;
        }
        .solutions-hex-icon {
            fill:#ffffff;
        }
        .solutions-hex-list__name {
            color:$brand-primary;
        }
    }
}
.solutions-hex-solid {
    width:87px;
    height:87px;
    position: absolute;
    top:13px;
    left:13px;
    fill:#ffffff;
    z-index:3;
}
.solutions-hex-border {
    width:113px;
    height:113px;
    position: relative;
    margin:-8px 0;
    display:block;
    fill:#666666;
    z-index:2;
}
.solutions-hex-icon {
    width:56px;
    height:56px;
    position: absolute;
    top:50%;
    left:50%;
    transform: translateX(-50%) translateY(-50%);
    fill:#666666;
    z-index:4;
}
.solutions-hex-list__name {
    position: absolute;
    top:100%;
    width:100%;
    text-align:center;
    left:0;
    color:#666666;
    font-weight:bold;
    padding:2px 0 0 0;
    font-family: $font-family-zilla;
}

/* regions */

.regions-container {
    background-repeat: no-repeat;
    background-position: 100% 0;
    height:98px;
    padding:4px 68px 0 0;
    display:block;
    position: relative;
    text-align: right;
    margin:0 0 30px;
    width: 100%;
    max-width:198px;
}
.regions-container__region {
    margin:0 0 3px;

    &.active {
        .regions-container__region-name {
            background:$brand-primary;
        }
    }
}
.regions-container__region-name {
    display:block;
    background:#666666;
    padding:4px 36px 5px 5px;
    font-weight:bold;
    color:#fff;
    font-size:13px;
    font-family: $font-family-zilla;
    text-transform: uppercase;
    min-width:94px;
    border-radius: 4px 0 0 4px;
    text-align: left; 
}
.regions-container__image {
    position: absolute;
    right:5px;
    top:0;
    width:98px;
    height:98px;
}

@media screen and (min-width: 620px) {
    .solutions-hex-container {
        max-width:none;
    }
    .solutions-hex-list {
        display:flex;
        justify-content: center;
    }
    .solutions-hex-list__item {
        padding:8px 0 0 0;
        margin:0 3px 35px;
    }
    .regions-container {
        margin:0 0 30px;
    }
}

@media screen and (min-width: $screen-md-min) {

    .products-split-container {
        display:flex;
    }
    .products-desktop-only {
        display:block;
    }
    .products-mobile-only {
        display:none;
    }
    .products-split-container__block {
        width:calc(100% - 670px);
    }
    .products-split-container__block:first-child {
        padding:0 40px 0 0;
        width:670px;
    }

    .solutions-hex-container {
        display:flex;
        margin:0 0 50px;
    }
    .solutions-list {
        margin:0;
        margin-right:-23px;
    
        &:after {
            content:'';
            height:100%;
            position: absolute;
            right:0;
            top:0;
            border-right:28px solid #fff;
            border-top:50px solid transparent;
            border-bottom:50px solid transparent;
        }
    }
    .solutions-list__item {
        padding:2px 40px 2px 8px;
    }
    
    .solutions-hex-list {
        display:flex;
    }
    .solutions-hex-list__item {
        margin:0 -10px 0 0;
        max-width:none;
        padding:0;
    
        &:before {
            content:'';
            position: absolute;
            background:#fff;
            width:110px;
            height:4px;
            right:0;
            top:50%;
            z-index:4;
            margin:-12px -49px 0 0;
            transform: rotate(-59.5deg);
        }
        &:after {
            content:'';
            position: absolute;
            background:#fff;
            width:110px;
            height:4px;
            right:0;
            top:50%;
            z-index:4;
            margin:4px -51px 0 0;
            transform: rotate(-59.5deg);
        }
    
        &:last-child {
            &:before,
            &:after {
                display:none;
            }
        }
    }
    .solutions-hex-solid {
        top:5px;
    }
}

@media screen and (min-width: 1124px) {
    .products-split-container__block {
        width:calc(100% - 800px);
    }
    .products-split-container__block:first-child {
        padding:0 100px 0 0;
        width:730px;
    }
}

.alternate-trade-names-container ul,
.alternate-trade-names {
    @include list-reset;
    margin:0 -15px 10px;
}
.alternate-trade-names-container li,
.alternate-trade-names__item {
    @extend .h3;
    padding:0 15px;
    font-size:$font-size-h3;
    margin:0 0 20px;
}
.alternate-trade-names-container h3,
.alternate-trade-names__name {
    font-size:$font-size-h3;
    margin:0;
}

@media screen and (min-width: $screen-md-min) {
    .alternate-trade-names-container ul,
    .alternate-trade-names {
        display:flex;
        flex-wrap: wrap;
    }
    .alternate-trade-names-container li,
    .alternate-trade-names__item {
        width:33.33%;
    }
}


.product-listing {
    @include list-reset;
    margin:0 -15px;
    display:flex;
    flex-wrap: wrap;
}
.product-listing__item {
    padding:0 15px 30px;
    margin:0;
    width:50%;
}
.product-listing__item-language {
    padding: 0 15px;
    margin: 0;
    width: 100%;
    display: inline !important;
}
.product-listing__link {
    display:block;
    text-decoration: none;
    color:$gray-darker;
}
.product-listing__link-image {
    display:block;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    margin:0 0 5px;

    &:before {
        content:'';
        display: block;
        padding-bottom:60%;
    }
}
.product-listing__link-name {
    font-weight:bold;
}

@media screen and (min-width: $screen-md-min) {
    .product-listing__item {
        width:33.33%
    }
}
@media screen and (min-width: $screen-lg-min) {
    .product-listing__item {
        width:25%
    }
}


.downloads-listing {
    @include list-reset;
    margin:0 -15px;
    display:flex;
    flex-wrap: wrap;
}
.downloads-listing__item {
    padding:0 15px 30px;
    margin:0;
    width:50%;
}
.download-item {
    display:block;
    text-decoration: none;
    color:$gray-darker;
    position: relative;

    &:hover,
    &:focus {
        .download-item__icon {
            background:#231F20;
        }
    }
}
.download-item__image {
    display:block;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    margin:0 0 5px;

    &:before {
        content:'';
        display: block;
        padding-bottom:100%;
    }
}
.download-item__icon {
    position: absolute;
    bottom:0;
    right:0;
    width:60px;
    height:60px;
    background:$brand-primary;
    fill:#fff;
    padding:10px;
    border-radius:5px 0 0 0;
}

@media screen and (min-width: $screen-md-min) {
    .downloads-listing__item {
        width:33.33%
    }
}
@media screen and (min-width: $screen-lg-min) {
    .downloads-listing__item {
        width:25%
    }
}