body {
  margin: 0;
  width: 100vw;
  overflow: hidden;
  overflow-y: auto;
  font-smooth: always;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.content-body {
  @include container-fluid;
  margin-top: 48px;
  clear:both;
}

.content-body-row,
.content-body-row-2-col {
  @include make-row;

  &:first-child {
    .content-body-col-1,
    .content-body-col-2 {
      padding-top: 0;
    }
  }

  &:last-child {
    .content-body-col-1,
    .content-body-col-2 {
      padding-bottom: 0;
    }

    .content-body-col-2 {
      .teaser {
        margin-bottom: 25px;
      }
    }
  }
}

%body-column {
  padding-top: 25px;
  padding-bottom: 25px;
  float: left;
  width: 100%;
  box-sizing: border-box;
}

.content-body-col-1 {
  @extend %body-column;
  @include make-md-column(9);
  @media screen and (min-width: $screen-md-min){
    padding-right: 50px;
  }

  .cta-block {
    float: left;
    // width: 100%;

    @media screen and (min-width: $screen-sm-min){
      // width: auto;
    }
  }
}

.content-body-col-full {
  @include make-md-column(12);
}

.content-body-col-2 {
  @extend %body-column;
  @include make-md-column(3);

  @media screen and (min-width: $screen-md-min) {
    border-left: 1px solid $brand-gray;
  }

  &.noborder {
    border: 0;
  }

  *:first-child {
    margin-top: 0;
  }

  .block {
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

// First row, right column hangs down into second row right column on desktop
// behaves as normal on mobile / tablet, above main content
.col-2-violator-down {
  position: relative;

  .content-body-col-1 {
    padding: 0;
  }

  .content-body-col-2 {
    padding-top: 0;
    padding-bottom: 0;

    .cta-block {
      padding: 0;
      @media screen and (min-width: $screen-sm-min) {
        padding: 0 25px;
      }
      @media screen and (min-width: $screen-md-min) {
        padding: 0;
      }
    }
  }

  @media screen and (min-width: $screen-md-min){

    .content-body-col-2 {
      position: absolute;
      top: 0;
      right: 0;
    }

    // remove padding top from next row to complete the illusion
    + .content-body-row {
      .content-body-col-1,
      .content-body-col-2 {
        padding-top: 0;
      }
    }
  }
}

.col-2-violator-up {
  position: relative;

  .content-body-col-2 {

    @media screen and (min-width: $screen-md-min){
      position: relative;
      margin-top: -195px;
      padding-left: 0;
      border: 0;
      z-index: 1;
    }
    @media screen and (min-width: $screen-md-min){
      padding-right: 0;
    }

    .cta-block {
      position: relative;
      overflow: hidden;


      @media screen and (min-width: $screen-md-min) {
        overflow: visible;
        box-shadow: none;
      }

      //Extend background color and any box-shadow behind the cta-block on larger
      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: -1;
        box-sizing: content-box;
        width: 100%;
        max-width: 25vw;
        height: 100%;
        background: inherit;
        // box-shadow: 0 0 15px $color-shadow;

        @media screen and (min-width: $screen-md-min) {
          padding-right: 25px; // right edge meets the header
        }
      }
    }
  }
}

.main-content-header-wrapper {
  @include signode-texture;
}

.main-content-header {
  @include container-fluid;
  padding-top: 20px;
  padding-bottom: 27px;

  .page-title {
    @extend %h1-large-2;
    @include make-md-column(9);
    padding-left: 0;
    margin: 0;
  }
}

.main-content-header-flex {
    .page-title {
      float:none;
      width:auto;
      margin:0 0 15px;
    }
}

.main-content-header-flex__right {
  .btn {
    min-width:230px;
    margin-bottom:10px;
  }
}

@media screen and (min-width: $screen-lg-min) {
  .main-content-header-flex {
    display:flex;
    justify-content: space-between;
    align-items: flex-end;

    .page-title {
      margin:0;
    }
  }
  .main-content-header-flex__left {
    width:calc(100% - 328px);
  }
  .main-content-header-flex__right {
    white-space: nowrap;

    .btn {
      min-width:0;
      margin-bottom:0;
    }
  }
}

.center {
  width: 100%;
  text-align: center;
}
