.why-signode-section-title {
  @extend %h1;
  text-align: left;
  margin-top: 0;
}

.why-signode {
  .wysiwyg-content {
    margin: 24px 0;
  }
}
