// Fontawesome mixins
// Usage
//
// .selector {
//  @include fa-icon-solid;
//  content: fa-content($fa-var-chevron-left);
// }
@mixin list-reset {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

@mixin fa-icon-brands {
  @include fa-icon;
  font-family: 'Font Awesome 5 Brands'; // sass-lint:disable-line variable-for-property
  font-weight: $font-weight-fa-regular;
  vertical-align: baseline;
}

@mixin fa-icon-regular {
  @include fa-icon;
  font-family: 'Font Awesome 5 Free'; // sass-lint:disable-line variable-for-property
  font-weight: $font-weight-fa-regular;
  vertical-align: baseline;
}

@mixin fa-icon-solid {
  @include fa-icon;
  font-family: 'Font Awesome 5 Free'; // sass-lint:disable-line variable-for-property
  font-weight: $font-weight-fa-solid;
  vertical-align: baseline;
}

// Strip margins from first child top
// and last child botton
@mixin strip-edge-margins {
  & > :first-child {
    margin-top: 0;
  }

  & > :last-child {
    margin-bottom: 0;
  }
}

// used for most containers
@mixin container-fluid {
  @extend .container-fluid;
  max-width: $container-fluid-max;
}

// used for main header container
@mixin container-breakout {
  @extend .container-fluid;
  max-width: $container-breakout-max;
}

@mixin absolute-center {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

@mixin vertical-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin field-label-overlay($label) {
  padding-left: 1em;

  & + #{$label} {
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: opacity 0.2s;
  }

  // reduce opacity when the input is in focus but nothing has been typed
  &:focus {
    &:placeholder-shown {
      &+ #{$label} {
        opacity: 0.5;
      }
    }
  }

  // display label as a style-able placeholder when a placeholder is shown
  // NOTE: field must have an empty placeholder ```placeholder=" "```
  &:placeholder-shown {
    &:not(:focus) {
      &+ #{$label} {
        opacity: 1;
      }
    }
  }
}

@mixin make-button {
  @extend .btn; // sass-lint:disable-line placeholder-in-extend
}
@mixin make-button-primary {
  @extend .btn-primary; // sass-lint:disable-line placeholder-in-extend
  @include make-button;
}


//
// Set a child image to be landscape on mobile, but portrait on desktop;
@mixin swap-image($desktop-columns: 3) {
  @include make-xs-column(12);
  @include make-sm-column($desktop-columns);
  padding: 0;
  height: $mobile-image-height;
  object-fit: cover;
  object-position: center;
  @media screen and (min-width: $screen-sm-min){
    height: 100vh;
  }
}

@mixin aspect-ratio($width, $height) {
  width: 100%;
  padding-bottom: ($height / $width) * 100%;
}

@mixin remove-link-underline {
  &,
  &:link,
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }
}

@mixin placeholder-color($c) {
  &::-webkit-input-placeholder { // Chrome/Opera/Safari
    color: $c;
  }

  &::-moz-placeholder { // Firefox 19+
    color: $c;
  }

  &:-ms-input-placeholder { // IE 10+
    color: $c;
  }

  &:-moz-placeholder { // Firefox 18-
    color: $c;
  }
}

@mixin signode-texture() {
  background-image: url('../images/signode-texture.jpg');
  background-color: $brand-gray;
  background-size: auto;
  background-repeat: repeat;
}

@mixin background-reset(){
  background-color: transparent;
  background-image: none;
}

@mixin menu-space-around() {
  display: flex;

  ul {
    display: flex;
    flex: 1 0 100%;
    width: 100%;
    justify-content: space-around;
    flex-wrap: wrap;

    .nav-item {
      display: inline-block;
      flex: 0 1 auto;
      padding-left: 0.5ex;
      padding-right: 0.5ex;
    }
  }
}

