// teaser grids

%teaser-grid-base {
  padding-left: 0;
  padding-right: 0;
  max-width: 100vw;
  width: 100%;
  float: left;
  box-sizing: border-box;

  @media screen and (min-width: $screen-md-min){
    @include make-row();
  }
  @media screen and (min-width: $screen-sm-min){
    padding-bottom: $teaser-grid-gutter;
  }

  &:last-child {
    padding-bottom: 0;
  }
}

%teaser-grid-item-base {
  @include make-xs-column(12, 0);
  margin-bottom: 48px;
  box-sizing: border-box;

  .figure-16-9 {
    @include make-row(0);
    width: 100%;
  }
}

.teaser-grid-2-up {
  @extend %teaser-grid-base;

  .teaser-grid-item {
    @include make-sm-column(6, $teaser-grid-gutter);
    @extend %teaser-grid-item-base;
    padding-left: 0;
    padding-right: 0;

    @media screen and (min-width: $screen-sm-min){
      padding-left: $teaser-grid-gutter/2;
      padding-right: $teaser-grid-gutter/2;

      // remove bottom margin on last row
      &:nth-last-child(1),
      &:nth-last-child(2) {
        margin-bottom: 0;
      }
    }

    // use single row, clear every "break"
    @media screen and (min-width: $screen-sm-min) {
      &:nth-child(2n+1) {
        clear: left;
      }
    }
  }
}

.teaser-grid-3-up {
  @extend %teaser-grid-base;

  .teaser-grid-item {

    @include make-sm-column(6, $teaser-grid-gutter);
    @include make-md-column(4, $teaser-grid-gutter);
    @extend %teaser-grid-item-base;
    padding-left: 0;
    padding-right: 0;

    @media screen and (min-width: $screen-sm-min){
      padding-left: $teaser-grid-gutter/2;
      padding-right: $teaser-grid-gutter/2;

      // remove bottom margin on last row
      &:nth-last-child(1),
      &:nth-last-child(2) {
        margin-bottom: 0;
      }
    }

    // use single row, clear every "break"
    @media screen and (min-width: $screen-sm-min) {
      &:nth-child(2n+1) {
        clear: left;
      }
    }

    @media screen and (min-width: $screen-md-min) {
      &:nth-child(2n+1) {
        clear: none;
      }

      &:nth-child(3n+1) {
        clear: left;
      }

      &:nth-last-child(3) {
        margin-bottom: 0;
      }
    }
  }
}
