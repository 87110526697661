.modal {
  display:none;
}
.modal, .modaal-wrapper { 
  text-align:center;
  
  .modal-content {
    position: static;
  }

  .modal-header {

  }

  .modal-body {
    padding-bottom: 24px;
  }

  .modal-footer {
    border-top: 1px solid $colorname-alto8-gray;
    padding-top: 24px;
  }

  .modal-title {
    @extend %h3;
    display: inline-block;
    margin: 1em 0 0.75em;
    color: $brand-primary;
  }
}
