img {
    max-width:100%;
}

.visuallyhidden,
.visually-hidden,
.visuallyHidden,
.sr-only {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    margin: -1px;
    padding: 0;
    border: 0;
    width: 1px;
	height: 1px;
	transition: none;

	&.focusable:focus {
		position: fixed;
		top: 0;
		left: 0;
		background: $brand-primary;
		color: #fff;
		overflow: visible;
		clip: auto;
		margin: 0;
		width: 100%;
		height: auto;
		padding: 10px;
		text-align: center;
		text-decoration: none;
		font-weight: bold;
		z-index: 9001;
	}
}