.language-switcher {
  @include make-row;

  .field-group {
    @include make-xs-column(12);
    @include make-sm-column(6);
    display: inline-block;
  }

  .select-wrapper {
    width: 100%;
  }

  .btn {
    margin-top: 1em;
  }
}
#culture-translation-message {
  font-style: italic;
  color:$brand-primary;
}
