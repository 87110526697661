.watermark-container {
  overflow: hidden;
  position: relative;
  z-index: 2;

  &.dark {
    background: $colorname-silver-rust;

    .watermark {
      color: $text-color-inverted;
      opacity: 0.6;
    }
  }

  .watermark {
    color: $colorname-silver-rust;
    font-family: $font-family-poppins;
    font-weight: $font-weight-bold;
    left: 50%;
    opacity: 0.1;
    pointer-events: none;
    position: absolute;
    top: 70%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
    z-index: -1;

    @media screen and (orientation: portrait) {
      transform: translate(-50%, -50%) rotate(90deg);
    }
  }
}
