.footer {
  padding-top: 40px;
  padding-bottom: 15px;
  font-size: $font-size-small;
  background: $brand-accent;
  color: $text-color-inverted;
  margin-top: 70px;

  @media screen and (min-width: $screen-md-min){
    padding-bottom: 72px;
    margin-top: 100px;
  }

  a {
    color: $text-color-inverted;
  }

  p {
    font-size: $font-size-small;
  }
 
  .light-color {
    color: $colorname-privacylight;
    padding-top: 53px;
    text-decoration: underline;

    @media screen and (min-width: $screen-md) {
      padding-top: 0;
    }

    a, button {
      @extend %light-link;
    }
  }
}

.footer-container {
  @include container-fluid;
}

.footer-logo {
  max-width: 280px;
  @media screen and (min-width: $screen-md-min){
    padding-bottom: 32px;
  }
}

.division {

  @media screen and (min-width: $screen-md) {
    padding-top: 37px;
  }

  a {
    white-space: nowrap;
  }
}

.copyright {
  white-space: nowrap;
}

.footer-column {
  @include make-md-column(3);
  margin-bottom: 45px;

  @media screen and (min-width: $screen-md-min){
    margin-bottom: 0;

    &.footer-column-1 {
      width:28%;
    }
    &.footer-column-2 {
      width:47%;
    }
  }
}

.footer-directory {
  @include make-md-column(6);
  padding: 0;

  a {
    @extend %accent-link;
    line-height: 1.5;
    font-weight: $font-weight-normal;
    margin: 0;

    &:after {
      display: none;
    }

    &:hover,
    &:focus {
      color: $colorname-silver-rust;
    }
  }
}

.footer-directory-list {
  list-style: none;
  padding: 0;
  margin: 0;
  line-height: 2.5;

  @media screen and (min-width: $screen-md) {
    float: left;
  }

  a {
    cursor: pointer;
    margin: 0;

    &:after {
      display: none;
    }
  }
  button {
    padding:0;
    border:0;
    background:none;
    text-decoration: underline;
  }
}

.icon-holder {
  font-size: 36px;
  display: block;
  width: 100%;
  clear: both;
  float: left;
}

%social-icon {
  @include remove-link-underline;
  display: inline-block;
  float: left;
  margin: 12px;

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }

  &:before {
    @include fa-icon-brands;
    float: left;
    color: $colorname-beige;
  }

  &[rel='external'],
  &[target='_blank'] {
    margin-right: 12px;

    &:after {
      display: none;
    }
  }

  &:hover,
  &:active,
  &:focus {
    color: initial;
  }
}

.follow-us-icon-head {
  margin: 0;
}

.linkedin-icon {
  @extend %social-icon;

  &:before {
    content: fa-content($fa-var-linkedin);
  }


  &:hover,
  &:focus,
  &:active {
    &:before {
      color: $colorname-linkedin;
    }
  }
}


.youtube-icon {
  @extend %social-icon;

  &:before {
    content: fa-content($fa-var-youtube);
  }

  &:hover,
  &:focus,
  &:active {
    &:before {
      color: $colorname-youtube;
    }
  }
}
