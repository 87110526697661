/*================================================================
            CLICK MENU OVERWRITE MAIN NAVIGATION STYLES
================================================================*/

.main-nav .cm-menu {
    // Multi-level
    a {
        color: $colorname-white;
        background:none;
        font-weight:700;

        &.has-sub::after {
            border-top-color: currentColor;
        }

        &:hover,
        &:focus {
            outline:none;
        }
    }
    .expander-wrap .has-sub {
        width:40px;
        margin-left:4px;

        &:hover,
        &:focus {
            outline:2px solid #fff;
        }
    }
    .expander-wrap .has-sub > span {
        background:$brand-primary;
    }

    li {
        margin:0;
        border:0;
        border-bottom:solid rgba(196,188,183,0.5) 1px;
    }

    // 1st level
    > li, > li > div {

        > a {
            background:none;
            font-size:20px;
            padding:10px 15px;

            &:hover,
            &:focus {
                color:$brand-primary;
            }
        }

        > div > a {

            &:hover,
            &:focus {
                background:none;
            }
        }

        // 2nd level
        > ul {
            background:#333;

            li {
                border:0;
            }
            a {
                padding: 5px 25px;

                &:hover,
                &:focus {

                }
            }
        }
        &.opened {
            > ul {
                padding:10px 0;
            }
        }
    }

    // Tablet/Desktop Styles
    @media screen and (min-width: $screen-md-min) {
        // Multi-level
        background: none;
        .expander-wrap  {
            display:flex;
            justify-content: center;
            align-items: center;
        }
        .expander-wrap .has-sub {
            height:34px;

            &:hover,
            &:focus {

                span {
                    border:0;
                }
            }
        }
        .expander-wrap .has-sub > span {
            background:transparent;
        }

        // 1st level
        > li {
            text-align: center;
        }
        > li, > li > div {

            > a {
                background: none;
                font-size:16px;
                padding:25px 0;

                &:hover,
                &:focus {
                    color:#fff;

                    span {
                        border-bottom:2px solid $brand-primary;
                    }
                }
            }

            // 2nd level
            > ul {
                background:#231f20;
                padding:0 !important;
                left:-20px;

                .expander-wrap  {
                    justify-content: space-between;
                }

                li {
                    margin: 0;
                    display:block;
                    text-align: left;
                }

                a {
                    padding:10px 20px;
                    font-size:15px;
                    width:100%;

                    &:hover,
                    &:focus {
                        background:$brand-primary;
                        color:#fff;
                    }

                    &.has-sub {
                        &:hover,
                        &:focus {
                            background:transparent;
                        }
                    }
                }
            }
        }
    }
}
