// webfonts @import statements
// sass-lint:disable no-url-domains
// sass-lint:disable no-url-protocols

@import url('https://use.fontawesome.com/releases/v5.0.6/css/all.css');

@font-face {
	font-family: 'Zilla Slab';
	src: url('../fonts/ZillaSlab-Bold.woff') format('woff'),
		url('../fonts/ZillaSlab-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal; 
}
@font-face {
	font-family: 'Zilla Slab';
	src: url('../fonts/ZillaSlab-Medium.woff') format('woff'),
		url('../fonts/ZillaSlab-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: 'Zilla Slab';
	src: url('../fonts/ZillaSlab-Regular.woff') format('woff'),
		url('../fonts/ZillaSlab-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'Zilla Slab';
	src: url('../fonts/ZillaSlab-SemiBold.woff') format('woff'),
		url('../fonts/ZillaSlab-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Open Sans';
	src: url('../fonts/OpenSans-Bold.woff') format('woff'),
		url('../fonts/OpenSans-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}
@font-face {
	font-family: 'Open Sans';
	src: url('../fonts/OpenSans-Regular.woff') format('woff'),
		url('../fonts/OpenSans-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Poppins';
	src: url('../fonts/Poppins-ExtraBold.woff') format('woff'),
		url('../fonts/Poppins-ExtraBold.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
}
@font-face {
	font-family: 'Poppins';
	src: url('../fonts/Poppins-Regular.woff') format('woff'),
		url('../fonts/Poppins-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto';
	src: url('../fonts/Roboto-Bold.woff') format('woff'),
		url('../fonts/Roboto-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}
@font-face {
	font-family: 'Roboto';
	src: url('../fonts/Roboto-Regular.woff') format('woff'),
		url('../fonts/Roboto-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

$font-family-zilla: 'Zilla Slab', serif;
$font-family-open: 'Open Sans', sans-serif;
$font-family-poppins: 'Poppins', sans-serif;
$font-family-font-awesome: 'Font Awesome\ 5 Free';
$font-family-roboto: 'Roboto', sans-serif;
$font-family-sarabun: "Sarabun", sans-serif;
