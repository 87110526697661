// Variables
// Please comment '[bootstrap] for any variables that are used by bootstrap'

///////////////////////////////
/// Color Names
///////////////////////////////

// Grays (sorted dark to light)
$colorname-black: #000;
$colorname-slate: #333;
$colorname-black-thunder: #231f20;
$colorname-boulder-gray: #747474;
$colorname-silver-rust: #c4bcb7;
$colorname-silver: #ccc;
$colorname-alto-gray: #ddd;
$colorname-alto8-gray: #d8d8d8;
$colorname-dusty-gray: #979797;
$colorname-alpine-gray: #e1dddb;
$colorname-beige: #c4bcb7;
$colorname-white: #fff;
//klaro-cookies colors
$color-darker-gray: #231f20;
$color-nice-orange: #b33d26;


// Colors
$colorname-cherry-red: #b33d26;
$colorname-linkedin: #0077B5;
$colorname-youtube: #c4302b;
$colorname-containertext: #333;
$colorname-privacylight: #666;
$colorname-slate-blue: #34657f;


///////////////////////////////
/// Color Variables
///////////////////////////////
$color-shadow-light: rgba(0, 0, 0, 0.16);
$color-shadow: rgba(0, 0, 0, 0.35);
$color-shadow-dark: rgba(0, 0, 0, 0.5);
$color-overlay: rgba(35, 31, 32, 0.8);
$section-dark: rgba(196, 188, 183, 0.5);

$brand-primary: $colorname-cherry-red;   // [bootstrap]
$brand-primary-dark: darken($brand-primary, 10%);
$brand-accent: $colorname-black-thunder;
$brand-gray: $colorname-alpine-gray;
$gray-darker: $colorname-black-thunder;  // [bootstrap]
$gray-dark: $colorname-dusty-gray; // [bootstrap]
$gray: $colorname-boulder-gray;            // [bootstrap]
$gray-light: $colorname-silver-rust;   // [bootstrap]
$gray-lighter: $colorname-alto-gray; // [bootstrap]
$color-form-border: $gray-dark;
$color-body: $colorname-white;
///////////////////////////////
/// Typography
///////////////////////////////
$text-color: $colorname-slate;   // [bootstrap]
$text-color-deemphasized: $colorname-boulder-gray;
$color-text-light: $colorname-silver-rust;
$text-color-inverted: $colorname-white;
$color-link: $brand-primary;


$font-family-sans-serif: $font-family-open; // [bootstrap]
$font-family-base: $font-family-sans-serif; // [bootstrap]
$font-size-base: 16px; // [bootstrap]
$line-height-base: (24px / 16px); // [bootstrap]
$font-size-small: 14px;
$font-size-seticon: 40px;

$font-weight-offset: 0;

$font-weight-light: 300 - $font-weight-offset;
$font-weight-normal: 500 - $font-weight-offset;
$font-weight-semibold: 600 - $font-weight-offset;
$font-weight-bold: 700 - $font-weight-offset;
$font-weight-extrabold: 800 - $font-weight-offset;

$font-weight-fa-regular: 400;
$font-weight-fa-solid: 900;

// Headings
$headings-font-weight: $font-weight-bold; // [bootstrap]
$headings-line-height: 1.1; // [bootstrap]

$font-size-h1: 38px; // [bootstrap]
$font-size-h1-desktop: 58px;
$font-size-h2: 34px; // [bootstrap]
$font-size-h2-desktop: 44px;
$font-size-h3: 28px; // [bootstrap]
$font-size-h3-desktop: 28px;
$font-size-h4: 20px; // [bootstrap]
$font-size-h4-desktop: 20px;
$font-size-h5: 14px; // [bootstrap]
$font-size-h5-desktop: 14px;

$font-size-h6: $font-size-base; // [bootstrap]

// Links
$link-color: $brand-primary; // [bootstrap]
$link-hover-color: $brand-primary; // [bootstrap]

///////////////////////////////
/// Validation State
///////////////////////////////

///////////////////////////////
/// Border Radius
///////////////////////////////
$border-radius-base: 5px; // [bootstrap]
$border-radius-large: 8px; // [bootstrap]
$border-radius-small: 3px; // [bootstrap]

////////////////////////////// /
/// Forms
///////////////////////////////
$input-height-base: 44px; // [bootstrap]
$input-border-radius: 0; // [bootstrap]
$input-color: $text-color; // [bootstrap]
$input-border: $colorname-black; // [bootstrap]

///////////////////////////////
/// Buttons
///////////////////////////////
$btn-border-radius-base: $border-radius-base; // [bootstrap]

///////////////////////////////
/// Other Variables
///////////////////////////////

// Grid spacing
$grid-gutter-width: 30px; // [bootstrap]
$teaser-grid-gutter: 50px;

$vertical-rhythm-base: 15px; // [bootstrap]
// $vertical-rhythm-half: round($vertical-rhythm-base / 2);
// $vertical-rhythm-double: $vertical-rhythm-base * 2;

// Aliases for some rhythm concepts
// $vertical-rhythm-elements: 10px;
// $vertical-rhythm-sections: $vertical-rhythm-double;
// $line-height-computed: $vertical-rhythm-base; //[bootstrap]

///////////////////////////////
/// Breakpoints & Containers
///////////////////////////////
$screen-xs: 480px; // [bootstrap]
$screen-sm: 768px; // [bootstrap]
$screen-md: 1000px; // [bootstrap]
$screen-lg: 1200px; // [bootstrap]

$container-breakout-max: 1280px;
$container-fluid-max: 1230px;

////////////////////////////////
/// Z-Axis
/////////////////////////////////

$z-background: 0;
$z-floating-content: 5;
$z-overlay: 10;
$z-top: 20;
////////////////////////////////
/// Buttons
/////////////////////////////////

$btn-font-weight: $font-weight-bold; // [bootstrap]
$btn-border-radius-base: $border-radius-small;

////////////////////////////////
/// Images
////////////////////////////////
$mobile-image-height: 52.86vw;
