.landing-hero {
  text-align: center;
  min-height: unset;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .content {
    @include make-xs-column(12);
    position: relative;
    max-width: 100vw;
    width: 100%;
    left: 50%;
    margin-top: 84px; // header height;
    transform: translate(-50%, 0);
    min-height: calc(80vh - 84px);
    padding: 8.5vh 26px 11vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    flex: 0;
    z-index: $z-floating-content;

    >* {
      display: block;
      flex: 0 0 auto;
      margin: 0;
      width: 100%;
    }

    @media screen and (min-width: $screen-sm-min) {
      max-width: 75%;
    }
    @media screen and (min-width: $screen-md-min) {
      max-width: 1280px;
      margin-top: 129px; // header height
      min-height: calc(80vh - 129px);
    }
  }

  .section-title {
    @include h1-large;
    color: $text-color-inverted;
    margin: 0 auto;
    margin-bottom: 35px;
    text-shadow: 0 5px 20px $color-shadow;
    max-width: 9em; // break headline as in the designs, without resorting to <br />
  }
}

.landing-section {
    margin:0 0 50px;

    &.landing-section--small {
      margin:0 0 20px;
    }
}