.signode-solution-system {
  &.homepage-section-breakout {
    background: transparent;
    position: relative;


    &:after {
      @include signode-texture;
      display: block;
      content: '';
      position: absolute;
      width: 100vw;
      height: 100%;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      z-index: -1;
    }
  }

  .homepage-section-title {
    @extend %h1;
    margin-bottom: 18px;
  }

  .homepage-section-title,
  .intro-text {
    font-weight: $font-weight-normal;
    text-align: left;
    @media screen and (min-width: $screen-xs-min) {
      text-align: center;
    }
  }

  .teaser-grid-homepage-featured {
    justify-content: space-around;
    padding-bottom: 64px;

    @media screen and (min-width: $screen-md-min){
      padding-bottom: 80px;
    }

    .teaser-grid-item {
      margin: 48px 0 0;

      @media screen and (min-width: $screen-sm-min) {
        margin-top: 0;
      }
      @media screen and (min-width: $screen-md-min) {
        flex: 0 1 33%;
      }
      @media screen and (min-width: $screen-lg-min) {
        flex: 0 1 20%;
      }
    }

    // after to prevent last item(s) from growing
    &:after {
      content: '';
      height: 0;
      margin: 0;
      flex: 1 0 40%;

      @media screen and (min-width: $screen-md-min) {
        flex: 0 1 33%;
      }
      @media screen and (min-width: $screen-lg-min) {
        display: none;
      }
    }
  }
}

.signode-solution-system-item {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
  padding: 0;
  text-align: center;

  .icon {
    width: 92px;
    flex: 0 1 79px;
    display: block;
    margin: 0 auto;

    @media screen and (min-width: $screen-md-min){
      display: none;
    }
  }

  .title {
    @extend %h2;
    color: $brand-primary;
    font-weight: $font-weight-bold;
    font-size: 40px;
    flex: 0 0 1em;
    display: block;
    text-align: center;
  }

  .text {
    flex: 1 0 auto;
    display: block;
  }

  .more {
    flex: 0;
    display: block;
  }
}

.signode-system-graphic {
  text-align: center;
  margin: auto;
  margin-top: 81px;
  margin-bottom: 25px;
  @media screen and (min-width: $screen-sm-min){
    margin-bottom: 28px;
  }

  img {
    width: 100%;
  }

  .text {
    display: none;
    @media screen and (min-width: $screen-md-min){
      display: inline-block;
    }
  }
}
