.category-listing {
    @include list-reset;
    margin:0 -15px;
}
.category-listing__item {
    padding:0 15px 30px;
    margin:0;
}
.category-listing__link {
    display:block;
    text-decoration: none;
    color:$gray-darker;
    position: relative;

    &:hover,
    &:focus {
        .category-listing__link-name {
            background:transparentize($color: $colorname-slate, $amount: .1);
        }
    }
}
.category-listing__link-image {
    display:block;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    margin:0 0 5px;

    &:before {
        content:'';
        display: block;
        padding-bottom:60%;
    }
}
.category-listing__link-name {
    font-weight:bold;
    color:#ffffff;
    position: absolute;
    bottom:0;
    left:0;
    font-size:$font-size-h2;
    padding:10px;
    background:transparentize($color: $brand-primary, $amount: .1);
}

// Solutions specific styling based on property selection

.category-listing {

    &.category-listing--layout-3 {
        padding-top:30px;
        
        .category-listing__link {

            &:hover,
            &:focus {
                .category-listing__link-name {
                    color:$brand-primary-dark;
                }
            }
        }

        .category-listing__link-name {
            position: static;
            background:none;
            color:$brand-primary;
            font-family: $font-family-zilla;
            text-align: center;
        }

        .category-listing__item:nth-child(1),
        .category-listing__item:nth-child(2) {
            .category-listing__link {

                &:hover,
                &:focus {
                    .category-listing__link-name {
                        background:$brand-primary;
                    }
                }
            }
            .category-listing__link-image {
                display:none;
            }
            .category-listing__link-name {
                position: static;
                background:#67675D;
                color:#fff;
                text-align: center;
                font-family: $font-family-open;
            }
        }
    }
}

@media screen and (min-width: $screen-sm) {
    .category-listing {
        display:flex;
        flex-wrap: wrap;

        &.category-listing--layout-3 {
            .category-listing__item:nth-child(1),
            .category-listing__item:nth-child(2) {
                width:50%
            }
        }
    }
    .category-listing__item {
        width:50%;
    }
}
@media screen and (min-width: $screen-md) {

}