.privacy-policy-warning {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: $brand-primary;
  color: $text-color-inverted;
  z-index: $z-top;
  box-shadow: 0 0 24px 0 $color-shadow-dark;

  .content {
    display: block;
    max-width: 1280px;
    width: 100%;
    margin: auto;
    padding: 6px 29px;
    @media screen and (min-width: $screen-md-min){
      white-space: nowrap;
      padding: 6px 40px;
    }

    .privacy-text {
      display: inline-block;
      vertical-align: middle;
      white-space: normal;
      text-align: center;
      width: 100%;
      @media screen and (min-width: $screen-sm-min) {
        text-align: left;
      }
      @media screen and (min-width: $screen-md-min) {
        width: 50%;
      }

    }

    .actions {
      display: inline-block;
      vertical-align: middle;
      width: 100%;
      text-align: center;

      @media screen and (min-width: $screen-sm-min){
        text-align: right;
      }

      @media screen and (min-width: $screen-md-min){
        width: 50%;
      }
    }
  }


  .buttons {
    display: inline-block;
  }

  .policy-link {
    display: inline-block;
    color: $text-color-inverted;
    text-decoration: underline;
    margin: 0.5em 1em;
    white-space: nowrap;

    &:after {
      display: none;
    }

    &:before {
      @include fa-icon-regular;
      content: '\f2d2';
      margin-right: 1ex;
    }
  }

  .privacy-text {
    margin: 20px 0;
  }

  .btn {
    margin: 24px 0;
    @media screen and (min-width: $screen-md-min){
      margin-top: 0;
      margin-bottom: 0;
    }

    &.agree {
      padding: 12px 24px 14px;
    }

    &.close {
      padding: 0;
      margin-left: 1em;
      background: none;
      border: 0;
      font-size: 24px;
    }
  }
}
